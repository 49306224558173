import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Юридическая консультация | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Консультации юристов – это особый продукт рынка правовых услуг, который пользуется большим спросом." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/yuridicheskaya-konsultaciya'
				name="Юридическая консультация | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Консультации юристов – это особый продукт рынка правовых услуг, который пользуется большим спросом."
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Юридическая консультация" card="Консультации юристов – это особый продукт рынка правовых услуг, который пользуется большим спросом." />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Юридическая консультация"}
			
				html={

				`
				<p class="TextText">
                	<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Право окружает нас везде: принятие наследства, судебное разбирательство с недобросовестным продавцом, обращение в государственные органы и так далее. Нередко на практике рождаются различного рода спорные ситуации, решить которые невозможно без дельного правового совета.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Консультации юристов – это особый продукт рынка правовых услуг, который пользуется большим спросом.
				</p>

				<p class="TextText">
					<b>Самостоятельно решение проблемы или консультация профессионала?</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Для большинства наших сограждан юриспруденция остается «дремучим лесом», а массив действующего законодательства полностью уничтожает затею самостоятельно решить свою проблему. К тому же, постоянное реформирование придает российскому законодательству не очень хорошее свойство – нестабильность. В такой обстановке не каждый юрист может поспеть за всеми обновлениями и изменениями, не говоря уже о простых граждан далеких от мира права.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Таким образом, зачастую человеку просто нужно выслушать мнение грамотного специалиста, относительно своей проблемы и конкретной ситуации. Это позволяет клиенту по-новому, с правовой точки зрения, взглянуть на сложившееся положение. Профессионал подскажет, какие действия необходимо совершить, в какие органы обратиться, как провести ту или иную операцию. Такие ценные советы становятся спасительными для многих клиентов.
				</p>

				<p class="TextH"><b>Преимущества консультации у юриста</b></p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Консультации у квалифицированного юриста имеют ряд неоспоримых преимуществ. Прежде всего, вы обращаетесь непосредственно к профессионалу, который имеет соответствующее образование, подготовку и опыт. Только грамотный юрист способен полностью вникнуть в суть вопроса, разобраться в его нюанса, взвесить все «за» и «против». Он сможет просчитать наперед возможные последствия, разложит по полочкам все негативные и позитивные стороны сделки, поможет подготовить юридические бумаги и даже стать вашим законным представителем в суде или органах государственной власти. Таким образом, вы будете застрахованы от всевозможных рисков и нелепых ошибок, ведь грамотный специалист всегда подходит к решению вашего конкретного вопроса сугубо индивидуально. Юридическая консультация будет иметь профессиональное и в тоже время максимально понятное толкование и разъяснение действующего законодательства, исходя из проблематики интересующего вопроса.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					К тому же, постепенно уходит в небытие устоявшийся порядок, когда юристы считались полезными исключительно в судебных заседаниях. Сейчас их услугами пользуются для прохождения разнообразных правовых процедур: регистрации, лицензирования, постановки на учет и так далее.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Основной минимум при производстве данных действий – это консультация юриста. В этом случае, клиент, даже решив самостоятельно осуществлять те или иные правовые операции, будет уверен в их законности, правильности прохождения и гарантированности результата.
				</p>

				<p class="TextH"><b>Как выбрать специалиста</b></p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Рынок насыщен правовыми фирмами и частными юристами, которые предлагают свои услуги. Выбрать из этого разнообразия подходящего специалиста порой бывает сложно. Основными критериями для оценки той или иной фирмы должны стать: профессионализм, опыт, положительные рекомендации бывших клиентов.
				</p>

				<p class="TextText">
					Компания Алмаз, действующая в Казани, обладает всеми вышеперечисленными качествами и оказывает правовую помощь по целому ряду вопросов, затрагивающих различные стороны жизни людей, возникающих в сферах:
				</p>

				<p class="TextH"><b>Жилищного права</b></p>
				<p class="TextH"><b>Гражданского права</b></p>
				<p class="TextH"><b>Наследственного права</b></p>
				<p class="TextH"><b>Земельного права</b></p>
				<p class="TextH"><b>и так далее</b></p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Консультации предоставляются в письменной и в устной форме по самым разным вопросам. Опытные специалист имеют отличные теоретические познания и практические навыки работы в сфере юриспруденции, которые позволяют говорить о реальных гарантиях оказания квалифицированной помощи.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Юристы подходят со всей ответственностью к изучению проблемы клиента. Консультация юриста может послужить основанием для дальнейшего плодотворного сотрудничества.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Консультации профессионалов – это полезный совет, позволяющий разрешить сложные практические ситуации.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
